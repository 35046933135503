import React from 'react';

const Footer = () => {
  return(
    <footer className="bg-black text-center py-6 px-4">
      <div className="italic text-xs disclaimer max-w-screen-lg mx-auto text-white">        
        *Please consult with a physician or other registered healthcare professional regarding any medical or health-related diagnosis or treatment options before starting a new exercise regime. Results may vary depending on starting point, goals, and effort. Exercise and proper diet are necessary to achieve and maintain weight loss and muscle definition.
      </div> 
    </footer>
  )
}

export default Footer;