import React, { Fragment} from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import '../../styles/global.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ogImage from '../../images/hero/hero-tennis.jpg'

import BuyContext from '../../Contexts/BuyContext';

const Layout = ({children, title}) => {
  return(
    <BuyContext>
      <StaticQuery 
        query={graphql`
          query {
            site {
              siteMetadata {
                description
                image
                title
                titleTemplate
                url
              }
            }
          }
        `}
          render={ data => {
              const {title, titleTemplate, description, url} = data.site.siteMetadata;

              return(
                <Fragment>
                  <Helmet
                    defaultTitle={`${titleTemplate.toUpperCase()}`}
                    titleTemplate={`%s | ${titleTemplate}`}
                  >
                    <meta name="description" content={description} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={ogImage} />

                    <html lang="en" />
                    {/* {data.shopifyShop.name}                   */}
                    {/* Add font link tags here */}
                  </Helmet>
                  <Header />
                  {children}
                  <Footer />
                </Fragment>
              )
          }}
      />
    </BuyContext>
  )
}

export default Layout;