import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { PageContext } from '../../Contexts/PageContext';

function Hero(){
  const { handlePackage } = React.useContext(PageContext);

  return (
    <div className="relative flex flex-col hero-module">   
      <div className="hidden md:flex">
        <StaticImage
          src="../../images/hero/hero-tennis.jpg"
          alt="Hero Image"
          loading="eager"
        />
      </div>
      <div className="flex md:hidden">
        <StaticImage
          src="../../images/hero/hero-tennis-mobile.jpg"
          alt="Hero Image Mobile"
          className=" hero-mobile"          
          loading="eager"
        />
      </div>    
      <div className="absolute w-full md:w-auto bottom-6 md:bottom-4 md:left-5 z-10 md:p-0">
        <div className="hidden md:flex flex-col items-center md:items-start justify-center text-center md:text-left font-gtbold price-box">
          <h2 className="text-xl md:text-xl font-bold md:pr-6 md:mr-6">From &nbsp;$229</h2>
          <div className="text-base font-bold afterpay text-center md:text-left mt-1 md:mt-0">
            <afterpay-placement 
              data-locale="en_US"
              data-currency="USD" 
              data-amount= "229"
              data-size="lg"
              style={{ margin: 0}}
            >
            </afterpay-placement>
          </div>          
        </div>
        <div className="mx-auto md:mx-0 text-center md:text-left">
          <button id="hero-cta" className="text-base font-medium py-2 px-8 rounded-full bg-primary hover:bg-primary-hover font-gtmedium" onClick={handlePackage} style={{ minWidth: '184px'}}>Buy the Machine</button>
        </div>
      </div>        
    </div>
  )
}

export default Hero;