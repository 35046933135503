import React from 'react';
import ByrdieLogo from '../../images/press-logos/nw-byrdie-white.svg';
import CosmoLogo from '../../images/press-logos/nw-cosmo-white.svg';
import HealthLogo from '../../images/press-logos/nw-health-white.svg';
// import RefineryLogo from '../../images/press-logos/nw-refinery29-white.svg';
import ShapeLogo from '../../images/press-logos/shape-white.png';
import VogueLogo from '../../images/press-logos/press-vogue-logo.png';

const PressLogosMobile = () => {
  return(
    <>
    <div className="flex md:hidden justify-between items-center flex-wrap px-5 pt-8 bg-gray press__logos--mobile">
      <img
        src={ByrdieLogo}
        alt="Byrdie"
        style={{ filter: 'brightness(0)', height: '25px'}}
        className="mb-8"
        height={25}
      />
      <img
        src={CosmoLogo}
        alt="Cosmopolitan"
        style={{ filter: 'brightness(0)', height: '25px'}}
        className="mb-8"
        height={25}
      />           
    </div>
    <div className="flex md:hidden justify-between items-center flex-wrap px-5 bg-gray press__logos--mobile">
      <img
        src={ShapeLogo}
        alt="Shape"
        style={{ filter: 'brightness(0)', height: '25px' }}
        className="mb-8"
        height={25}
      />
      <img
        src={VogueLogo}
        alt="Vogue"
        style={{ filter: 'brightness(0)', height: '25px'}}
        className="mb-8"
        height={25}
      /> 
      <img
        src={HealthLogo}
        alt="Health"
        style={{ filter: 'brightness(0)', height: '25px'}}
        className="mb-8"
        height={25}
      />
    </div>
    </>
  )
}

export default PressLogosMobile;