import React from 'react';
import GridVideo from '../../videos/lp-vid2.mp4';
import { PageContext } from '../../Contexts/PageContext';

function Alternate(){
  const { handlePackage } = React.useContext(PageContext);

  return(
    <div className="grid__layout pt-10 md:py-14">
      <div className="mx-auto max-w-screen-2xl flex flex-wrap items-center px-0 md:px-16 ">
        <div className="w-full md:w-2/4 order-2 mt-8 md:mt-0">
        {/* <div className="md:rounded-lg md:overflow-hidden" style={{width: "100%", padding: "125% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/555805483?autoplay=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="tennis teaser"></iframe></div>  */}
        <video className="md:rounded-lg" autoPlay muted playsInline loop>
          <source src={GridVideo} type="video/mp4" />
        </video>
        </div>
        <div className="w-full md:w-2/4 md:pl-20 px-5 md:px-0 md:order-2">
          <h3 className="font-gtbold text-3.5xl md:text-5xl mb-8 md:mb-16 uppercase">Train like Nadal</h3>
          <p className="text-xl md:text-2xl mb-1 md:mb-5">Protect Your Back</p>
          <span className="block text-sm md:text-base mb-8 md:mb-10">“Getting my glutes strong and firing has really helped with my back”, says Nadal.*</span>
          <p className="text-xl md:text-2xl mb-1 md:mb-5">High Intensity, Low Impact</p>
          <span className="block text-sm md:text-base mb-8 md:mb-10">Our version of the squat can strengthen the stabilizing muscles around your knees to help prevent injury.</span>
          <p className="text-xl md:text-2xl mb-1 md:mb-5">Faster on the court</p>
          <span className="block text-sm md:text-base mb-4 md:mb-6">Lateral glute strength makes you faster from one side of the court to the other. Our free app programs show you how!</span>   
          <span className="font-gtlight-obl text-xs block mb-8 md:mb-10">*Rafael Nadal’s High-Intensity Workout. WSJ. May 15, 2021.</span>       
          <button id="train-cta" className="text-base font-medium py-2 px-8 rounded-full bg-primary hover:bg-primary-hover font-gtmedium" onClick={handlePackage}>Buy the Machine</button>          
        </div>
      </div>
    </div>
  )
}

export default Alternate;