import React from 'react';
import Video from '../../videos/lp-vid3.mp4'

const TheDetails = () => {
  return(
    <div className="the__details">
      <div className="flex flex-wrap items-center max-w-screen-2xl mx-auto pb-8 px-0 md:p-16">
        <div className="w-full md:w-2/4 md:pr-12 order-2 px-5 md:px-0">
          <h4 className="font-gtbold text-3.5xl md:text-5xl text-center md:text-left uppercase mb-6">The Details</h4>
          <div className="specs text-sm md:text-base">
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Dimensions</span>
              <span className="w-2/4">45 1/2" x 20" x 39 1/2"</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Extendable Seat for Heights</span>
              <span className="w-2/4">5'0"-6'3"</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Folded Height</span>
              <span className="w-2/4">7’’ at highest point</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Maximum User Weight</span>
              <span className="w-2/4">250 lbs</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Machine Weight </span>
              <span className="w-2/4">29 1/2 lbs</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Material</span>
              <span className="w-2/4">Steel</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Assembly</span>
              <span className="w-2/4">No-tools Assembly (parts click into place)</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Warranty</span>
              <span className="w-2/4">1-year Limited Manufacturer's Warranty</span>
            </p>
          </div>
        </div>
        <div className="block w-full md:w-2/4 md:pl-12 mb-8 md:mb-0 md:order-2">
          {/* <div className="md:rounded-lg md:overflow-hidden" style={{width: "100%", padding: "125% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/557773620?autoplay=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="tennis teaser"></iframe></div>  */}
          <video className="md:rounded-lg" autoPlay muted playsInline loop>
            <source src={Video} type="video/mp4" />
          </video>         
        </div>
      </div>
    </div>
  )
}

export default TheDetails;