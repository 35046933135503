import React from 'react';
import { PageContext } from '../../Contexts/PageContext';
import { StaticImage } from 'gatsby-plugin-image';

const ProductBuyBox = () => {
  const { handlePackage } = React.useContext(PageContext);

  return(
    <div className="max-w-screen-2xl mx-auto px-5 md:px-16 flex flex-wrap items-center product__buy_box">
      <div className="flex w-full md:w-2/4 mb-8 md:mb-0">
        <StaticImage
          src="../../images/machine/machine2.jpg"
          alt="The Machine"
          width={800}
          loading="eager"
        />
      </div>
      <div className="w-full md:w-2/4 md:pl-28">
        <h3 className="font-gtbold text-3.5xl md:text-5xl mb-5">The Machine</h3>
        <div className="mb-7">
          From $229.00
          <afterpay-placement 
            data-locale="en_US"
            data-currency="USD" 
            data-amount= "$229"
            style={{ margin: 0}}
          >
          </afterpay-placement>
        </div>
        <button id="buy-box-cta" className="block text-base font-medium py-2 px-8 mx-auto md:m-0 rounded-full bg-primary hover:bg-primary-hover font-gtmedium" onClick={handlePackage}>Shop the Machine</button>
      </div>
    </div>
  )
}

export default ProductBuyBox;