import React, {useState, useEffect} from 'react';
import Client from 'shopify-buy'

import { BuyContext as Context } from '../Contexts/PageContext';

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  domain: process.env.GATSBY_SHOP_NAME
})

const BuyContext = ({ children }) => {
  let initialStoreState = {
    client,
    adding: false,
    checkout: {lineItems: []},
    products: [],
    shop: {}
  }

  const [store, updateStore] = useState(initialStoreState);

  useEffect(() => {
    
    const initializeCheckout = async () =>{
      // check for an existing cart
      const isBrowser = typeof window !== undefined
      // const existingCheckoutID = isBrowser ? localStorage.getItem('shopify_checkout_id') : null
      // const existingCheckoutID = null

      const setCheckoutInState = checkout => {
        if(isBrowser){
          localStorage.setItem('shopify_checkout_id', checkout.id);
        }

        updateStore(prevState => {
          return {...prevState, checkout}
        })
      }

      const createNewCheckout = () => store.client.checkout.create();
      // const fetchCheckout = id => store.client.checkout.fetch(id);

      // if(existingCheckoutID){
      //   try{
      //     const checkout = await fetchCheckout(existingCheckoutID)
      //     //Make sure this cart hasn't already been purchased
      //     if(!checkout.completedAt){
      //       setCheckoutInState(checkout);
      //       return
      //     }
      //   }catch(e){
      //     localStorage.setItem('shopify_checkout_id', null)
      //   }
      // }

      const newCheckout = await createNewCheckout()
      setCheckoutInState(newCheckout);
    }

    initializeCheckout()    
  }, [store.client.checkout])

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: (variants) => {
          let lineItemsToUpdate = variants;          
          
          updateStore(prevState => {
            return {...prevState, adding: true}
          })

          const { checkout, client } = store

          const checkoutId = checkout.id

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return {...prevState, checkout, adding: false}
              })
            })
        }
      }}>
      {children}
    </Context.Provider>
  )
}

export default BuyContext;