import React from 'react';
import ByrdieLogo from '../../images/press-logos/nw-byrdie-white.svg';
import CosmoLogo from '../../images/press-logos/nw-cosmo-white.svg';
import HealthLogo from '../../images/press-logos/nw-health-white.svg';
// import RefineryLogo from '../../images/press-logos/nw-refinery29-white.svg';
import ShapeLogo from '../../images/press-logos/shape-white.png';
import VogueLogo from '../../images/press-logos/press-vogue-logo.png';

const PressLogos = () => {
  return(
    <div className="hidden md:flex justify-center items-center nowrap px-5 py-16 bg-gray press__logos">
      <img
        src={ByrdieLogo}
        alt="Byrdie"
        style={{ marginRight: '100px', filter: 'brightness(0)'}}
        width={180}
        height={27}
        loading="eager"
      />
      <img
        src={CosmoLogo}
        alt="Cosmopolitan"
        style={{ marginRight: '100px', filter: 'brightness(0)'}}
        width={140}
        height={27}
        loading="eager"
      />
      <img
        src={HealthLogo}
        alt="Health"
        style={{ marginRight: '100px', filter: 'brightness(0)'}}
        width={97}
        height={27}
        loading="eager"
      />
      <img
        src={VogueLogo}
        alt="Vogue"
        style={{ marginRight: '100px', filter: 'brightness(0)'}}
        width={109}
        height={30}
        loading="eager"
      />
      <img
        src={ShapeLogo}
        alt="Shape"
        style={{ filter: 'brightness(0)' }}
        width={74}
        height={37}
        loading="eager"
      />
    </div>
  )
}

export default PressLogos;