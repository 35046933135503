import * as React from "react"
import Layout from '../components/Layout/Layout';
import Hero from '../components/HeroModules/Hero';
import Alternate from '../components/Sections/Alternate';
import Appmodule from '../components/Sections/AppModule';
import PressLogos from '../components/Sections/PressLogos';
import PressLogosMobile from '../components/Sections/PressLogosMobile';
import TheDetails from '../components/Sections/TheDetails';
import ProductBuyBox from '../components/Sections/ProductBuyBox';
import Faq from '../components/Sections/Faq';
import Package from '../components/Packages/Package';
import { PageContext } from '../Contexts/PageContext';
import firebase from 'gatsby-plugin-firebase';

// markup
const IndexPage = () => {  
  
  const [showPackage, setShowPackage] = React.useState(false); 

  const handlePackage = (e) => {
    document.body.style.overflow = 'hidden';
    setShowPackage(true);
    // Tracking package modal open
    firebase.analytics().logEvent('package_modal_open', {
      section: e.target.id
    });
  }
  const closePackage = (e) => {
    document.body.style.overflow = 'visible';
    setShowPackage(false);
    // Tracking package modal open
    firebase.analytics().logEvent('package_modal_close');
  }

  return (    
    <Layout title="Our squats improve your tennis game">
      <PageContext.Provider
        value={{ handlePackage, closePackage }}
      >
        <Hero />        
        <div className="bg-gray-fc px-5 py-8 md:px-10 md:py-16 text-center">
          <h1 className="text-2xl font-gtmedium">Our squats improve<br className="block md:hidden" /> your tennis game</h1>
        </div>        
        <Alternate />
        <PressLogosMobile />
        <Appmodule />
        <PressLogos />
        <TheDetails />
        <ProductBuyBox />
        <Faq />
        {showPackage &&
          <Package />
        }        
      </PageContext.Provider>
    </Layout>
  )
}

export default IndexPage;