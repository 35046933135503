import React from "react";
import Logo from '../../images/db-logo-white.png'

const Header = () => {
  return(
    <header className="flex items-center justify-center absolute w-full md:w-auto md:right-5 top-6 md:top-5 z-10" style={{ filter: 'invert(1)'}}>
      <img 
      src={Logo} 
      alt="DB Method Logo"
      loading="eager"
      width={280}
      height={38}
      />
    </header>
  )
}

export default Header;