import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { PageContext, BuyContext } from '../../Contexts/PageContext';
import Item from './Item';

import VIP from '../../images/vip.png';
import CustomerService from '../../images/customer-service.png';
import Shield from '../../images/shield.png';

import firebase from 'gatsby-plugin-firebase';

const Package = () => {
  const { closePackage } = React.useContext(PageContext);
  const [ dbCare, setDbCare] = React.useState(false);

  const initialState = {
    selectedPackage: -1,
    package: {},
    dbCareInfoPopup: false
  }

  const [ layout, updateLayout ] = React.useState(initialState);

  const {
    addVariantToCart,
    store: { checkout },
  } = React.useContext(BuyContext)

  const selectPackage = (index, item) => {
    updateLayout(prevState => {
      console.log(prevState)
      return {...prevState, selectedPackage: index, package: item}
    })
  }

  const handleAddToCart =(e) => {  
    console.log('aasdfsd');
    let el = e.target;
    let selected = layout.package;  
    let products = selected.packageProducts.products;    
    let itemsToAddToCart = [];
    
    for(let i=0;i<products.length;i++){
      itemsToAddToCart.push({
        variantId: products[i],
        quantity: 1,
        customAttributes: [{key: "kit", value: selected.title + ' Kit'},{key: "kitid", value: String(new Date().getTime())}]
      })
    }
    if(dbCare){
      itemsToAddToCart.push({
        variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMDAzNTk3MTc3NjU4Mg==",
        quantity: 1
      })
    }
    
    el.innerText = 'Adding to cart...';
    el.setAttribute('disabled', true);
    
    // Tracking add to cart events
    firebase.analytics().logEvent('add_to_cart',{
      package: selected.title
    });
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'AddToCart');
      }
    }    
    let addCartReturn = addVariantToCart(itemsToAddToCart)
    addCartReturn.then(function() {
      firebase.analytics().logEvent('checkout_created', {
        cid: checkout.id
      });
      window.location.href = checkout.webUrl.replace('the-db-method.myshopify.com', 'thedbmethod.com') + '&discount=TENNIS';
      // window.open('/cart', '_self')
    })
    .catch((error) => {
      console.error('Error:', error);
      el.innerText = 'Add to Cart';
      el.disabled = false;
    });
  }
  
  return(
    <div className="fixed inset-0 overflow-y-auto package-wrapper bg-gray-f2 pt-16 pb-36 px-5 md:px-10 z-50" role="dialog" aria-modal="true">
      <button className="absolute top-5 left-5 md:top-10 md:left-10 flex items-center text-xs cursor-pointer uppercase" onClick={closePackage}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
        Back to Machine
      </button>
      <StaticQuery 
        query={graphql`
          query{
            allContentfulPackage {
              nodes {
                id
                title
                subtext
                packageItem {
                  image {
                    file {
                      url
                    }
                  }
                  title
                  price
                  packageProducts {
                    includes
                    products
                  }
                }
              }
            }
          }
        `}
        render={data =>{
          let content = data.allContentfulPackage.nodes[0];
          let items = content.packageItem;

          return(
            <div className="max-w-screen-md mx-auto text-center content">
              <h4 className="text-4xl uppercase font-gtbold mb-10">{content.title}</h4>
              <p className="uppercase text-sm font-gtmedium mb-6">{ content.subtext }</p>
              <div className="flex flex-wrap md:flex-nowrap -mx-4 mb-10 packages text-left md:text-center">
                {items.map((item, index) =>     
                  <Item item={item} index={index} selected={layout.selectedPackage === index ? true : false} selectPackage={selectPackage} />
                )}
              </div>
            </div>
          )
        }
        }
      />
      <div className="max-w-screen-md mx-auto text-center content">
        <div className="flex flex-col">
          <p className="uppercase text-sm font-gtmedium mb-6">Extend your warranty</p>
          <div className="flex flex-col bg-white rounded-xl p-5 md:px-14 md:py-10">
            <div className="flex w-full justify-between">
              <label className="flex items-center cursor-pointer">
                <input type="checkbox" className="mr-4 w-4 h-4" onChange={(e) => setDbCare(e.target.checked)} />
                Add DBCare
              </label>
              <span>
                $30
              </span>
            </div>
            <div className="text-sm mt-2 ml-8 text-left">
              <p>Better be sore than sorry! All our machines include a one year limited warranty, and DBCare extends this to two full years of manufacturer’s coverage. <button className="underline" onClick={() => updateLayout(prevState => { return {...prevState, dbCareInfoPopup: true}})}>Learn More</button></p>
              <ul className="text-xs mt-10">
                <li className="mb-7 pl-8 relative">
                  <img 
                    src={Shield}
                    alt="Shield Icon"
                    width={25}
                    className="absolute left-0 -top-1"
                  />
                  Hassle-free machine support and servicing
                </li>
                <li className="mb-7 pl-8 relative">
                  <img 
                    src={CustomerService}
                    alt="Customer Service Icon"
                    width={25}
                    className="absolute left-0 -top-1"
                  />
                  Priority Customer Service
                </li>
                <li className="pl-8 relative">
                  <img 
                    src={VIP}
                    alt="VIP Icon"
                    width={25}
                    className="absolute left-0 -top-1"
                  />
                  Be the first to know about new products, new workouts and more
                </li>
              </ul>
            </div>
          </div>
        </div>   
        <div className="fixed left-0 right-0 bottom-0 flex justify-between items-center p-0 md:px-14 md:py-4 bg-white">
          <h3 className="uppercase text-2xl font-gtbold tracking-wide hidden md:block">{layout.package['title'] ? layout.package['title'] : 'The Basics'} </h3>
          <div className="w-full md:w-auto">
            <button className={`w-full md:w-auto text-base font-medium py-4 px-12 md:rounded-full bg-primary hover:bg-primary-hover font-gtmedium uppercase ${layout.selectedPackage === -1 ? 'pointer-events-none opacity-40' : ''}`} onClick={handleAddToCart}>Add to Bag</button>
          </div>
        </div>             
      </div>
      {layout.dbCareInfoPopup &&
          <div className="flex items-center fixed inset-0 bg-black bg-opacity-50">
            <div className="relative bg-black-2e text-white text-center rounded-2xl max-w-screen-sm w-11/12 mx-auto py-20 px-16">
              <button className="absolute top-5 right-5" onClick={() => updateLayout(prevState => { return {...prevState, dbCareInfoPopup: false}})}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.663" height="18.663" viewBox="0 0 18.663 18.663"><defs><style></style></defs><g transform="translate(-272.437 -7.01)"><g transform="translate(273.145 7.717)"><path stroke="#ffffff" strokeWidth="2" d="M-63.855-5421.283l17.249,17.249" transform="translate(63.855 5421.283)"></path><path stroke="#ffffff" strokeWidth="2" d="M-46.606-5421.283l-17.249,17.249" transform="translate(63.855 5421.283)"></path></g></g></svg>
              </button>
              <h5 className="uppercase font-gtbold text-4xl tracking-wider mb-2">Db Care</h5>
              <span className="block mx-auto bg-peach mb-3" style={{ width: '200px', height: '2px'}}></span>
              <span className="font-gtlight text-xl">$30</span>
              <div className="font-gtlight mt-6">
                <span className="font-gtmedium">What is covered by DB CARE?</span><br/>
                Free repair or, if your issue can’t be serviced, replacement of The Machine, for 2 years.*<br/><br/>
                <span className="font-gtmedium">Priority Customer Service</span><br />
                Expedited response time on all inquiries<br/><br/>
                DB Care must be added to a machine order at the time of purchase and cannot be added after an order has been submitted.<br /><br />
                *Limitations and exclusions apply.  For more information see<br />
                <a href="https://thedbmethod.kustomer.help/en_us/what-is-dbcare--BJHcyhtor" target="_blank" rel="noreferrer" className="underline">What is DB Care?</a> 
              </div>
            </div>
          </div>
        }
    </div>
  )
}

export default Package;